


































































































































































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {AgGridVue} from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Vietnamese as VietnameseLocale} from 'flatpickr/dist/l10n/vn.js';
import UserSuggestion from '@/components/suggestion/user-suggestion/UserSuggestion.vue';
import CustomerGroupTreeDropdown from "@/components/CustomerGroupTreeDropdown.vue";
import {CustomerGroupService} from "@/views/customer-group/CustomerGroupService";
import {TreeModel} from "@/components/tree/TreeModel";
import {ExportType, PaymentMethods} from '@/constants/Order';
import ReceiptUserSuggestion from "@/views/cash-book/receipt/component/receipt-user.suggestion.vue";
import ReceiptGroupSuggestion from "@/views/cash-book/receipt/component/receipt-group-suggestion.vue";
import moment from 'moment';
import {formatNumber} from "@/common";
import _each from 'lodash/each';
import _cloneDeep from 'lodash/cloneDeep';
import _map from 'lodash/map';
import CustomerSuggestion from "@/components/suggestion/customer-suggestion/CustomerSuggestion.vue";
import {DateSearchType, ListDateSearchType, OptionDateSearchType, ReceiptType} from "@/constants/receipt";
import {QueryLoadOption} from "@/models/QueryLoadOptions";
import {ReceiptService} from "@/views/cash-book/receipt/receipt.service";
import {SearchResult} from "@/models/SearchResult";
import {ReceiptResult} from "@/models/cash-book/receipt/ReceiptResult";
import {ActionResult} from "@/models/ActionResult";
import {RenderCashBookResult} from "@/models/cash-book/RenderCashBookResult";
import DropdownCellRenderer from "@/components/ag-grid/DropdownCellRenderer.vue";
import CustomerCellComponent from "@/components/ag-grid/CustomerCellComponent.vue";
import CellReceiptCodeComponent from "@/views/cash-book/receipt/cell-render/CellReceiptCode.vue";
import CellReceiptCertificateCode from "@/views/cash-book/receipt/cell-render/CellReceiptCertificateCode.vue";
import CellReceiptStatus from "@/views/cash-book/receipt/cell-render/CellReceiptStatus.vue";
import {ReceiptUser} from "@/models/cash-book/receipt/ReceiptUser";
import {Common} from "@/helper/common";
import ExportOptions from "@/views/order/order-list/ExportOptions.vue";
import SelectPageSize from "@/components/select-page-size/select-page-size.vue";

@Component({
    components: {
        AgGridVue,
        'v-select': vSelect,
        flatPickr,
        UserSuggestion: UserSuggestion,
        CustomerGroupTreeDropdown,
        ReceiptUserSuggestion,
        ReceiptGroupSuggestion,
        CustomerSuggestion,
        ExportOptions,
        SelectPageSize
    },
})

export default class CashBookList extends Vue {
    @Ref('filterCard') filterCard: any;
    @Ref('exportOptions') exportOptions!: any;
    configDateTimePickerRange = {
        locale: VietnameseLocale,
        dateFormat: 'd-m-Y',
        mode: 'range',
        defaultDate: [new Date(), new Date()]
    };

    typeDate = {id: DateSearchType.CreateDate, name: new OptionDateSearchType(DateSearchType.CreateDate).name};
    listDateSearchType = ListDateSearchType;
    createDate: any = null;
    fromDate: string | undefined = moment(new Date(), 'DD-MM-YYYY').format();
    toDate: string | undefined = moment(new Date(), 'DD-MM-YYYY').format();
    customerGroups: any[] = [];
    paymentMethods: any = PaymentMethods;
    listCashBook: ReceiptResult[] = [];

    // AgGrid
    gridApi: any = null;
    gridOptions: any = {};
    defaultColDef = {
        sortable: true,
        resizable: true,
        suppressMenu: true,

    };
    columnDefs: any = null;
    frameworkComponents: any = null;
    pinnedTopRowData: any = null;

    //search
    loadOptions: QueryLoadOption = new QueryLoadOption();
    receiptService = new ReceiptService();
    totalRows = 0;
    totalPages = 0;
    currentPage = 1;

    searchQuery = "";
    receiptGroupId: string = '';
    customerGroupId: any = null;
    customerId: string;
    receiptUserPhone: string;
    paymentMethodSelected: any[] = [];
    creatorId: string;

    // Render Cashbook
    totalPayment = 0;
    totalReceiver = 0;
    openingStock = 0;
    selectedIds: Array<string> = [];

    constructor() {
        super();
        this.loadOptions.select = ['id', 'type', 'code', 'creatorFullName', 'codeReference', 'referenceType',
            'customerId', 'customerName', 'customerPhoneNumber', 'receiptFullName', 'receiptPhoneNumber',
            'receiptGroupName', 'amount', 'paymentMethodString', 'dateRecording', 'createTime', 'note', 'branchName',
            'status', 'isAutomation', 'creatorId', 'note'];
    }

    beforeMount() {
        this.getColumnDefaultApi();
        this.gridOptions = {
            localeText: {noRowsToShow: 'Không tìm thấy phiếu thu - chi'},
        };

        this.frameworkComponents = {
            'dropdownCellRenderer': DropdownCellRenderer,
            'cellReceiptStatus': CellReceiptStatus,
            'customerCellComponent': CustomerCellComponent,
            'cellReceiptCodeComponent': CellReceiptCodeComponent,
            'cellReceiptCertificateCode': CellReceiptCertificateCode
        };
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.getCustomerGroup();
        setTimeout(() => {
            this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
        })
        /* =================================================================
          NOTE:
          Header is not aligned properly in RTL version of agGrid table.
          However, we given fix to this issue. If you want more robust solution please contact them at gitHub
        ================================================================= */
        if (this.$vs.rtl) {
            // const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
            // header.style.left = "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px"
        }

        this.search(true);
    }

    @Watch('createDate')
    getCreateDate(value: any) {
        console.log(value);
    }

    get usersData() {
        return this.listCashBook;
    }

    get paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize()
        else return 20
    }

    //
    @Watch('currentPage')
    onCurrentPageChange(value: number, oldValue: number) {
        this.search(false);
    }

    beginSearch(page: number) {
        if (this.searchQuery == undefined || this.searchQuery.length < 2) {
            return;
        }
        this.search();
    }

    resetColFilters() {
        this.searchQuery = "";
        this.receiptGroupId = '';
        this.customerGroupId = null;
        this.customerId = '';
        this.receiptUserPhone = '';
        this.paymentMethodSelected = [];
        this.creatorId = '';
        this.createDate = null;
        this.fromDate = moment(new Date(), 'DD-MM-YYYY').format();
        this.toDate = moment(new Date(), 'DD-MM-YYYY').format();

        this.filterCard.removeRefreshAnimation()
        this.search(true);
    }

    onSelectionChanged(event: any) {
        this.selectedIds = _map(event.api.getSelectedRows(), (item: any) => {
            return item.id
        });
    }

    deliveryDateClosed(selectedDates: any, dateStr: any, instance: any) {
        if (selectedDates.length == 2) {
            this.fromDate = moment(selectedDates[0], 'DD-MM-YYYY').format();
            this.toDate = moment(selectedDates[1], 'DD-MM-YYYY').format();
        }
    }

    onCustomerSelected(value: any[]) {
        if (value && value.length > 0) {
            this.customerId = value[0].id;
        } else {
            this.customerId = '';
        }
    }

    selectReceiptGroup(values: any[]) {
        if (values && values.length > 0) {
            this.receiptGroupId = values[0].id;
        } else {
            this.receiptGroupId = '';
        }
    }

    removeReceiptGroup() {
        this.receiptGroupId = '';
    }

    onUserSelected(result: any[]) {
        if (result && result.length > 0) {
            this.creatorId = result[0].id;
        } else {
            this.creatorId = '';
        }
    }

    getUser(value: ReceiptUser) {
        if (value) {
            this.receiptUserPhone = value.fullName;
        }
    }

    formatNumber(value: number) {
        return formatNumber(value);
    }

    openExportOptions() {
        this.exportOptions.open();
    }

    changePageSize(pageSize: number) {
        this.gridApi.paginationSetPageSize(pageSize);
        this.currentPage = 1;

        this.search(false);
    }

    async onExportFile(exportType: ExportType) {
        let loadOptions = _cloneDeep(this.loadOptions) as any;
        loadOptions.exportType = exportType;

        if (exportType === ExportType.Selected) {
            loadOptions.selectedIds = this.selectedIds;
        }

        this.openLoading(true);
        await this.receiptService.exportExcel(loadOptions, this.typeDate ? this.typeDate.id : undefined, this.fromDate, this.toDate);
        this.openLoading(false);
    }

    private getCustomerGroup() {
        const customerGroupService = new CustomerGroupService();
        customerGroupService.search()
            .then((result: TreeModel[]) => {
                this.customerGroups = result;
            });
    }

    private getColumnDefaultApi() {
        this.columnDefs = [
            {
                headerName: '',
                field: 'selection',
                filter: false,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                headerCheckboxSelection: true,
                width: 50,
                pinned: 'left',
                suppressSizeToFit: true,
            },
            {
                headerName: 'STT',
                field: 'no',
                filter: true,
                sortable: false,
                width: 50,
                editable: false,
                pinned: 'left',
                suppressSizeToFit: true,
                cellStyle: {textAlign: "center"}
            },
            {
                headerName: 'Loại phiếu',
                field: 'receiptGroupName',
                filter: true,
                sortable: false,
                width: 150,
                editable: false,
                pinned: 'left',
                suppressSizeToFit: true,

            },
            {
                headerName: 'Ngày ghi nhận',
                field: 'dateRecording',
                filter: true,
                sortable: false,
                width: 150,
                editable: false,
                pinned: 'left',
                suppressSizeToFit: true,
                cellRenderer: function (params: any) {
                    if (params.value) {
                        return moment(params.value).format('DD-MM-YYYY HH:mm');
                    }
                }
            },
            {
                headerName: 'Ngày tạo',
                field: 'createTime',
                filter: true,
                sortable: false,
                width: 150,
                editable: false,
                pinned: 'left',
                suppressSizeToFit: true,
                cellRenderer: function (params: any) {
                    if (params.value) {
                        return `<i class="text-sm">` + moment(params.value).format('DD-MM-YYYY HH:mm') + `</i>`
                    }
                }
            },
            {
                headerName: 'Mã phiếu',
                field: 'code',
                filter: true,
                sortable: false,
                width: 180,
                pinned: 'left',
                suppressSizeToFit: true,
                editable: false,
                cellRenderer: 'cellReceiptCodeComponent'
            },
            {
                headerName: 'Hình thức',
                field: 'isAutomation',
                filter: true,
                sortable: false,
                width: 120,
                editable: false,
                cellRenderer: function (parmas: any) {
                    if (parmas.value !== null && parmas.value !== undefined) {
                        if (parmas.value) {
                            return 'Tự động'
                        } else {
                            return 'Tạo phiếu'
                        }
                    }
                },
            },
            {
                headerName: 'Khách hàng/Nhà cùng cấp',
                field: 'customerName',
                filter: true,
                sortable: false,
                width: 180,
                editable: false,
                cellRenderer: 'customerCellComponent'
            }, {
                headerName: 'Người nhận/nộp',
                field: 'receiptFullName',
                filter: true,
                sortable: false,
                width: 180,
                editable: false
            },
            {
                headerName: 'Hình thức thanh toán',
                field: 'paymentMethodString',
                filter: true,
                sortable: false,
                width: 150,
                editable: false,
            },
            {
                headerName: 'Trạng thái',
                field: 'status',
                filter: true,
                sortable: false,
                width: 150,
                editable: false,
                cellRenderer: 'cellReceiptStatus'
            },
            {
                headerName: 'Ngưới tạo',
                field: 'creatorFullName',
                filter: true,
                sortable: false,
                width: 150,
                editable: false
            }, {
                headerName: 'Tiền thu',
                field: 'receiverAmount',
                filter: true,
                resizable: true,
                width: 120,
                editable: false,
                cellRenderer: function (parmas: any) {
                    if (parmas.value) {
                        return formatNumber(parmas.value)
                    } else {
                        return 0
                    }
                },
                cellStyle: {textAlign: "right"}
            },
            {
                headerName: 'Tiền chi',
                field: 'payAmount',
                filter: true,
                resizable: true,
                width: 120,
                editable: false,
                cellRenderer: function (parmas: any) {
                    if (parmas.value) {
                        return formatNumber(parmas.value)
                    } else {
                        return 0
                    }
                },
                cellStyle: {textAlign: "right"}
            },
            {
                headerName: 'Showroom',
                field: 'branchName',
                editable: false,
                width: 160,
                filter: true
            }, {
                headerName: 'Mã tham chiếu',
                field: 'codeReference',
                filter: true,
                sortable: false,
                width: 180,
                editable: false,
                cellRenderer: 'cellReceiptCertificateCode'
            },
            {
                headerName: 'Ghi chú',
                field: 'note',
                sortable: false,
                width: 250,
                editable: false,
            }
        ];
    }

    private search(isRender: boolean = false) {
        this.loadOptions.take = this.paginationPageSize;
        this.loadOptions.skip = (this.currentPage - 1) * this.paginationPageSize;
        this.renderQuery();
        this.openLoading(true);

        this.receiptService.searchCashBook(this.loadOptions, this.typeDate.id, this.fromDate, this.toDate)
            .finally(() => this.openLoading(false))
            .then((result: SearchResult<ReceiptResult>) => {
                if (result.code >= 0) {
                    _each(result.items, (it: ReceiptResult, index) => {
                        it.no = 1 + index + (this.currentPage - 1) * this.paginationPageSize;
                        if (it.type === ReceiptType.Payment) {
                            it.payAmount = it.amount;
                        } else {
                            it.receiverAmount = it.amount;
                        }
                    });

                    this.listCashBook = result.items;
                    if (isRender) {
                        setTimeout(() => {
                            this.$vs.loading({
                                container: '#card-with-loading',
                                scale: 0.45
                            })
                            this.receiptService.renderCashBook(this.loadOptions, this.typeDate ? this.typeDate.id : undefined, this.fromDate, this.toDate)
                                .finally(() => this.$vs.loading.close('#card-with-loading > .con-vs-loading'))
                                .then((result1: ActionResult<RenderCashBookResult>) => {
                                    if (result1.data) {
                                        this.totalRows = result1.data.totalRows;
                                        this.setTotalPage(this.totalRows);
                                        this.totalPayment = result1.data.totalPayment;
                                        this.totalReceiver = result1.data.totalReceiver;
                                        this.openingStock = result1.data.openingStock;

                                        this.pinnedTopRowData = this.createData();
                                    }
                                });
                        }, 100)
                    }
                }
            })
    }

    private renderQuery() {
        const common = new Common();
        let renderQuery: any[] = [];

        if (this.receiptUserPhone && this.receiptUserPhone !== null && this.receiptUserPhone !== '') {
            renderQuery.push(common.renderQuery('receiptPhoneNumber', '=', this.receiptUserPhone));
            renderQuery.push('and');
        }

        if (this.creatorId && this.creatorId !== null && this.creatorId !== '') {
            renderQuery.push(common.renderQuery('creatorId', '=', this.creatorId));
            renderQuery.push('and');
        }

        if (this.receiptGroupId) {
            renderQuery.push(common.renderQuery('receiptGroupId', '=', this.receiptGroupId));
            renderQuery.push('and');
        }

        if (this.searchQuery && this.searchQuery.trim()) {
            renderQuery.push(common.renderQuery('normalizedName', 'contains', this.searchQuery));
            renderQuery.push('and');
        }

        if (this.customerGroupId && this.customerGroupId !== null && this.customerGroupId !== '') {
            renderQuery.push(common.renderQuery('customerGroupId', '=', this.customerGroupId));
            renderQuery.push('and');
        }

        if (this.customerId && this.customerId !== null && this.customerId !== '') {
            renderQuery.push(common.renderQuery('customerId', '=', this.customerId));
            renderQuery.push('and');
        }

        if (this.paymentMethodSelected && this.paymentMethodSelected.length > 0) {
            let renderQueryPayment: any[] = [];

            _each(this.paymentMethodSelected, (item: any, index: number) => {
                renderQueryPayment.push(common.renderQuery('receiptPayments.PaymentMethod', '=', item.id));
                if (index < this.paymentMethodSelected.length - 1) {
                    renderQueryPayment.push('and');
                }
            })

            renderQuery.push(renderQueryPayment);
        }

        this.loadOptions.filterQuery = renderQuery.length == 0 ? '' : JSON.stringify(renderQuery);
    }

    private createData() {
        let result: any = [];

        result.push({
            payAmount: this.totalPayment,
            receiverAmount: this.totalReceiver,
        });

        return result;
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }

    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;
        if (totalRows == 0) {
            this.totalPages = 0;
        } else if (totalRows <= this.paginationPageSize) {
            this.totalPages = 1;
        } else {
            this.totalPages = Math.ceil((totalRows / this.paginationPageSize));
        }
    }
}

